import { defineComponent } from 'vue'
import Statsig from 'statsig-js'
import BrowsersApi from '../apis/BrowsersApi'
import StatsigEvent from '../common/StatsigEvent'

export default defineComponent({
    data() {
        return {
            logPersistInterval: null,
        }
    },
    mounted() {
        this.startLogPersistInterval()
    },
    beforeUnmount() {
        this.stopLogPersistInterval()
    },
    methods: {
        startLogPersistInterval() {
            this.stopLogPersistInterval()
            this.logPersistInterval = window.setInterval(this.logPersistTime, 1000)
        },
        stopLogPersistInterval() {
            if (this.logPersistInterval) {
                window.clearInterval(this.logPersistInterval)
                this.logPersistInterval = null
            }
        },
        async logPersistTime() {
            if (!window.browser_id) return

            const landingInfo = JSON.parse(localStorage.getItem('MWM_LANDING_INFO')) || {}
            let { landingInMili, persistInSec } = landingInfo[window.browser_id] || {
                landingInMili: Date.now(),
                persistInSec: 0,
            }

            if (new Date(landingInMili).getDate() !== new Date().getDate()) {
                landingInMili = Date.now()
                persistInSec = 0
            }

            landingInfo[window.browser_id] = { landingInMili, persistInSec }
            const durationInSec = Math.floor((Date.now() - landingInMili) / 1000)
            if (
                (persistInSec < 30 && durationInSec >= 30) ||
                (persistInSec < 120 && durationInSec >= 120) ||
                (persistInSec < 300 && durationInSec >= 300) ||
                (persistInSec < 600 && durationInSec >= 600) ||
                (persistInSec < 900 && durationInSec >= 900) ||
                (persistInSec < 1800 && durationInSec >= 1800)
            ) {
                await this.postPersistTime(durationInSec)
                landingInfo[window.browser_id].persistInSec = durationInSec
            }
            localStorage.setItem('MWM_LANDING_INFO', JSON.stringify(landingInfo))
        },
        async postPersistTime(durationInSec) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.PERSIST,
                payload: {
                    duration: durationInSec,
                },
            })
        },
        async logNavigation(page) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.NAVIGATE,
                payload: { page },
            })
        },
        async logNavigateForPayment(page) {
            if (['worksheet', 'bingo', 'flashcard'].includes(page)) {
                page = 'make a ' + page
            } else if (page === 'account') {
                page = 'my account'
            }
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.NAVIGATE_FOR_PAYMENT,
                payload: { page },
            })
        },
        async logChoosePlan(period, trial, planType, page) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.CHOOSE_PLAN,
                value: page,
                payload: { period, trial, planType, page },
            })
        },
        async logPaymentSubmittion(period, trial, planType, page) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.PAYMENT_SUBMISSION,
                payload: { period, trial, planType, page },
            })
        },
        async logPaymentSuccess(period, trial, planType, page) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.PAYMENT_SUCCESS,
                payload: { period, trial, planType, page },
            })
        },
        async logFreeSubscriptionSignup(page) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.FREE_SUBSCRIPTION_SIGNUP,
                payload: { page },
            })
        },
        async logSeenNewSignUpModal(page) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.SEEN_SIGNUP_OVERLAY,
                payload: { page },
            })
        },
        async logNewSignUp(page = 'account') {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.NEW_USER_SIGNUP,
                payload: { page, authMethod: 'manual', authType: 'create account' },
            })
        },
        async logSeenEmailOverlay() {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.SEEN_EMAIL_OVERLAY,
            })
        },
        async logClickMakePrintable(page) {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.PUBLISH_BUTTON_CLICK,
                payload: { page },
            })
        },
        async getFreePlanLayout() {
            return await this.$store.dispatch('abtests/loadFreePlanLayoutTest')
        },
        async logSocialAuthAttempt(authType = 'login') {
            await this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.AUTHENTICATION_ATTEMPTED,
                payload: {
                    authMethod: 'google',
                    authType,
                },
            })
        },
    },
})

export const initializeStatsig = async () => {
    if (!window.browser_id) {
        return Promise.reject('No browser id')
    }

    window.statsig = Statsig.default || Statsig
    if (!window.statsig?.initialize) {
        return Promise.reject('Statsig not initialized')
    }

    const uuid = window.user?.uuid || (await getBrowserUuid())

    if (!uuid) {
        return Promise.reject('No user id')
    }

    const abStableID = await getCustomStableID()

    const statsigUser = getStatsigUserPayload({
        uuid,
        custom_ab_stable_id: abStableID,
        created_at: window.user?.created_at,
    })

    try {
        await window.statsig?.initialize(window.statsig_client_key, statsigUser, {
            environment: {
                tier: window.env === 'local' ? 'development' : window.env,
            },
        })

        return Promise.resolve()
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getEntityStatsigValue = (entity) => {
    let title = 'Worksheet'
    switch (entity) {
        case 'bingo':
            title = 'Bingo Cards'
            break
        case 'flashcard':
            title = 'Flashcards'
            break
        default:
            break
    }

    return title
}

export const getBrowserUuid = async () => {
    let uuid = localStorage.getItem('CUSTOM_AB_STABLE_ID')

    if (!uuid) {
        try {
            const res = await BrowsersApi.get(window.browser_id)
            uuid = res.data.uuid
            localStorage.setItem('CUSTOM_AB_STABLE_ID', uuid)
        } catch (e) {
            console.error(e)
        }
    }

    return uuid
}

export const getCustomStableID = async () => {
    let abStableID
    if (window.user?.custom_ab_stable_id) {
        abStableID = window.user.custom_ab_stable_id
    } else {
        abStableID = localStorage.getItem('CUSTOM_AB_STABLE_ID')
        if (!abStableID) {
            abStableID = await getBrowserUuid()
        }
    }

    return abStableID
}

export const getStatsigUserPayload = (user) => {
    return {
        userID: user.uuid,
        custom: {
            new_user: isEligibleNewUser(user),
        },
        customIDs: {
            systemID: user.custom_ab_stable_id ?? localStorage.getItem('CUSTOM_AB_STABLE_ID'),
        },
    }
}

export const isEligibleNewUser = (user = null) => {
    if (window?.new_browser) return true
    if (!user || !user?.created_at) return false
    //Temporary check for new users. to be replaced with more experiment specific evaluation
    return new Date(user.created_at) >= new Date('2023-12-22')
}
