import apiClient from './apiClient'

export default {
    get(user_id) {
        return apiClient.get('/account/user/' + user_id).then((response) => {
            return response.data
        })
    },
    update(user_id, data) {
        return apiClient
            .put('/account/user/' + user_id, {
                ...data,
            })
            .then((response) => {
                return response.data
            })
    },
    getAbGroup() {
        return apiClient.get('/user/abGroup').then((response) => {
            return response.data
        })
    },
    updateBrowser(user_id, data) {
        return apiClient
            .put('/account/user/update-initial-browser/' + user_id, {
                ...data,
            })
            .then((response) => {
                return response.data
            })
    },
}
