export const dangerToast = {
    title: 'Error',
    autoHideDelay: 5000,
    variant: 'danger',
    solid: true,
}

export const successToast = {
    title: 'Success',
    autoHideDelay: 5000,
    variant: 'success',
    solid: true,
}
