export default Object.freeze({
    GO_FONT_PREMIUM: 'go_font_premium',
    GO_PREMIUM: 'go_premium',
    NEW_USER_VISIT: 'new-user-visit',
    USER_VISIT: 'user-visit',
    AUTHENTICATE: 'authenticate',
    AUTHENTICATION_ATTEMPTED: 'authentication-attempted',
    AUTHENTICATION_SUCCEEDED: 'authentication-succeeded',
    PUBLISH_DOCUMENT: 'publish-document',
    PUBLISH_BUTTON_CLICK: 'publish-button-click',
    PUBLISH_SUCCESS: 'publish-success',
    REPUBLISH_SUCCESS: 'republish-success',
    CREATE_DOCUMENT: 'create-document',
    NEW_DOCUMENT: 'new-document',
    NEW_BINGO_DOCUMENT: 'new-bingo-document',
    NEW_FLASHCARD_DOCUMENT: 'new-flashcard-document',
    NEW_WORKSHEET_DOCUMENT: 'new-worksheet-document',
    PERSIST: 'persist',
    NAVIGATE: 'navigate',
    NAVIGATE_FOR_PAYMENT: 'navigate-for-payment',
    CHOOSE_PLAN: 'choose-plan',
    PAYMENT_SUBMISSION: 'payment-submission',
    PAYMENT_SUCCESS: 'payment-success',
    FREE_SUBSCRIPTION_SIGNUP: 'free-subscription-signup',
    NEW_USER_SIGNUP: 'new-user-signup',
    SEEN_EMAIL_OVERLAY: 'seen-email-overlay',
    SEEN_SIGNUP_OVERLAY: 'seen-signup-overlay',
    ONBOARDING_PROCESS: 'onboarding-process-step',
    HEADER_STYLES_BTN_CLICK: 'header-styles-toggle-button-click',
})
